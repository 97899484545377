<template>
  <div class="h-100 w-100 d-flex align-items-center justify-content-center" style="font-size: 400%" v-if="autreTexte">
    <div class="d-flex flex-column align-items-center">
      <b>{{ autreTexte.pageNonTrouvee }}</b>
      <div>
        <button class="btn btn-lg btn-info-secondaire" @click="$router.push('/')">{{autreTexte.retourMenu}}</button>
      </div>
    </div>
  </div>
</template>

<script src="./pageNonTrouvee.js"></script>

<style scoped>
.btn-lg{
     font-size: 50%!important;     
}
</style>
